export class DataType {
    constructor({
                    name,
                    type,
                    readonly,
                    allowed,
                    data_relation,
                    is_list = false,
                    description = null,
                    rules = {},
                    required = false,
                    default_value = null
                }) {
        this.name = name;
        this.type = type
        this.is_data_type = true
        this.is_object = false // deprecated use is_dict cuz we like python
        this.is_dict = false
        this.is_list = is_list

        this.required = required
        if (readonly) {
            this.readonly = readonly
        } else {
            this.readonly = false
        }
        this.data_relation = data_relation
        if (this.data_relation) {
            this.allowed = []
        } else {
            this.allowed = allowed
        }
        if (this.type === "string" && this.allowed) {
            this.type = "enum"
        }
        this.rules = rules
        this.custom_component = parse_custom_component(description)
        this.default = default_value
    }
}

export class DictType {
    constructor({name, type, schema, readonly = false, description = null, is_list = false, label = ""}) {
        this.name = name;
        this.type = type
        this.is_data_type = false
        this.is_object = true
        this.is_dict = true
        this.is_list = is_list
        this.schema = get_schema(schema)
        this.readonly = readonly
        this.type = "dict"
        this.label = label
        this.custom_component = parse_custom_component(description)
    }
}

function get_schema(schema) {
    let current_key
    let current_schema
    let ret_schema = {}
    try {
        Object.keys(schema).map(function (key) {
                current_key = key
                current_schema = schema
                if (schema[key].type === "dict") {
                    ret_schema[key] = new DictType({
                        schema: schema[key].schema,
                        name: key,
                        ...schema[key]
                    })
                } else if (schema[key].type === "list") {
                    if (schema[key].schema.type === "dict" || parse_custom_component(schema[key]?.description)) {
                        ret_schema[key] = new DictType({
                                ...schema[key].schema,
                                name: key,
                                readonly: !!schema[key]?.readonly,
                                is_list: true,
                                description: schema[key]?.description,
                                label: schema[key].label
                            }
                        )
                    } else {
                        ret_schema[key] = new DataType({
                                ...schema[key].schema,
                                name: key,
                                is_list: true,
                                description: schema[key].description,
                                default_value: schema[key].default,
                                readonly: schema[key].readonly
                            }
                        )
                    }
                } else {
                    ret_schema[key] = new DataType({
                            ...schema[key],
                            name: key,
                            default_value: schema[key].default
                        }
                    )
                }
            }
        )
        return ret_schema
    } catch (e) {
        console.log("###### error parsing schema #######")
        console.log("current_key", current_key)
        console.log("current_schema", current_schema)
        console.log("current_schema", schema)
        throw e
    }

}

export class SchemaType {
    constructor(
        {
            resource, schema = {},
            resource_methods = [],
            item_methods = [],
            table_view = [],
            card_view = {left: [], right: []},
            description = null,
            actions = {},
            icon = "mdi-bug"

        }) {
        this.resource = resource
        this.resource_methods = resource_methods
        this.item_methods = item_methods

        this.schema = get_schema(schema)
        let internal_actions = {}
        for (let key of Object.keys(actions)) {
            internal_actions[key] = new SchemaType({
                resource: key,
                schema: actions[key].schema,
                resource_methods: ["POST"],
                item_methods: ["POST"],
                icon: actions[key].icon
            })
        }
        this.icon = icon
        this.actions = internal_actions
        this.table_view = table_view
        this.card_view = card_view
        this.custom_component = parse_custom_component(description)
        this.custom_card_component = parse_custom_card_component(description)
        if (description && !(this.custom_component || this.custom_card_component)) {
            console.log("Invalid regex", description)
        }
        this.is_object = true
    }
}


function parse_custom_component(description) {
    if (description === null) {
        return null
    }
    if (description === undefined) {
        return null
    }
    if (typeof description !== "string") {
        return null
    }
    let regex = '#inde_component:[A-Za-z]*'
    if (description.match(regex)) {
        return description.split(":")[1]
    } else {
        return null
    }
}

function parse_custom_card_component(description) {
    if (description === null) {
        return null
    }
    if (description === undefined) {
        return null
    }
    if (typeof description !== "string") {
        return null
    }
    let regex = '#inde_card_component:[A-Za-z]*'
    if (description.match(regex)) {
        return description.split(":")[1]
    } else {
        return null
    }
}
