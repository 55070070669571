<template>
    <v-app>
        <Navbar/>
        <v-main>
            <v-container fluid>
                <router-view />
            </v-container>
            <cookie-law theme="dark-lime"/>
        </v-main>
    </v-app>
</template>

<script>
import Navbar from "@/views/components/Navbar.vue";
import CookieLaw from 'vue-cookie-law'

export default {
    name: 'App',
    components: {
        CookieLaw,
        Navbar,
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
};
</script>
<style>


</style>
