import { render, staticRenderFns } from "./Spacing.vue?vue&type=template&id=31cc31d0&scoped=true&"
import script from "./Spacing.vue?vue&type=script&lang=js&"
export * from "./Spacing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31cc31d0",
  null
  
)

export default component.exports