import {image_mixin} from "@/mixins/image_mixin";

export let rooms_data = {
    image: image_mixin.methods.req("rooms/ocean_view_queen/IMG_0909.jpg"),
    text: `
Each air-conditioned room or suite is uniquely different with a sea view overlooking the pool or a garden view,
facing the tropical landscape.  Our rates include complimentary beach shuttle to Grand Anse Beach. 
Breakfast & laundry service is also offered for an additional fee.
    `
    ,
    icons: [
        {
            mdi: "md-rv_hookup",
            title: "Shuttle service"
        }
    ],
    rooms: [
        {
            name: "Ocean View Suite",
            image: image_mixin.methods.req("rooms/ocean_view_suite/bed4.jpg"),
            link: "ocean_view_suite",
            features: ["Four poster Queen bed", "Ocean view", "Bathtub"],
            price: {
                now: 290,
            },
            description: `
                A suite built with seclusion & romance in mind. 
                The Ocean View Suite suite has its own private entrance with a staircase that leads to a Queen four poster bed,
                seating area & open bathroom.  
                The suite has unobstructed, breathtaking views of the ocean & Grand Anse beach, 
                with lots of natural light as well as an antique claw foot bathtub, perfect for relaxing.`,
            room_numbers: [1]
        },
        // {
        //     name: "Garden/Sea View Studio",
        //     image: image_mixin.methods.req("new/room_sea_view.jpg"),
        //     link: "garden_view_studio_apartment",
        //     features: ["Queen bed", "Garden view"],
        //     price: {
        //         now: 100,
        //     },
        //     description: "Our Ocean View room has hardwood floors with a balcony overlooking the pool with beautiful ocean views.  Includes a modern bathroom with rain showerhead.\n" +
        //         "All rooms & suites have modern Air-Conditioning units with remote.  Breakfast or dinner service offered for a fee.",
        //     room_numbers: [2, 3]
        // },
        // {
        //     name: "Ocean View Attic Suite",
        //     image: image_mixin.methods.req("new/room_sea_view.jpg"),
        //     link: "ocean_view_attic_suite",
        //     features: ["King/Queen bed", "Ocean view", "Large Balcony"],
        //     price: {
        //         now: 250,
        //     },
        //     description: "Our Ocean View room has hardwood floors with a balcony overlooking the pool with beautiful ocean views.  Includes a modern bathroom with rain showerhead.\n" +
        //         "All rooms & suites have modern Air-Conditioning units with remote.  Breakfast or dinner service offered for a fee.",
        //     room_numbers: [4]
        // },
        {
            name: "Ocean View King Bed Suite",
            image: image_mixin.methods.req("rooms/ocean_view_king/IMG_1151.jpg"),
            link: "ocean_view_king_bed_suite",
            features: ["Ocean view", "Balcony", "WIFI"],
            price: {
                now: 320,
            },
            description: `
            One of our biggest suites includes French doors that lead to a large balcony with sweeping ocean views, 
            overlooking the pool & Grand Anse beach. 
            Hardwood floors with antique wardrobe closet, overhead fan, modern tile bathroom with rain shower head.`,
            room_numbers: [5]
        },
        {
            name: "Ocean View Queen Bed Suite",
            image: image_mixin.methods.req("rooms/ocean_view_queen/IMG_0920.jpg"),
            link: "ocean_view_queen_bed_suite",
            features: ["Queen bed", "Ocean view", "Balcony"],
            price: {
                now: 200,
            },
            description: `
Our Deluxe Ocean View room has one of the best views with a top floor balcony facing West. 
Wake up to breathtaking ocean views from your balcony overlooking the pool, 
includes a modern bathroom with rain showerhead.
`,
            room_numbers: [6, 7, 9, 10]

        },
        // {
        //     name: "Mountain View King Bed Suite",
        //     image: image_mixin.methods.req("rooms/mountain_view_queen/IMG_0964.jpg"),
        //     link: "mountain_view_king_bed_suite",
        //     features: ["King bed", "Garden view", "Balcony"],
        //     price: {
        //         high_season: 150,
        //         low_season: 120,
        //         now: 150,
        //     },
        //     description: "Our Garden View room has hardwood floors & balcony with beautiful garden views, overlooking the tropical hillside.  Includes a modern bathroom with rain showerhead.\n" +
        //         "All rooms & suites have modern Air-Conditioning units with remote.  Breakfast or dinner service offered for a fee.",
        //     room_numbers: [8]
        //
        // },
        {
            name: "Mountain View King Bed Suite",
            image: image_mixin.methods.req("rooms/mountain_view_queen/IMG_0964.jpg"),
            link: "mountain_view_king_bed_suite",
            features: ["Queen bed", "Mountain view", "Large Balcony", "Desk"],
            price: {
                now: 110,
            },
            description: `
            Our Mountain View suite is a large suite with a private sunroom, 
            a perfect place to relax with the natural light & lush garden view, 
            overlooking the green hillside.  
            Includes a modern bathroom with a rain-showerhead.
            `,
            room_numbers: [11, 12]
        },

    ]
}